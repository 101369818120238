import {
  createTask,
  deleteTask,
  duplicateTask,
  getLanguages,
  getPhoneNumbers,
  getTask,
  getTasks,
  getLinkTaskList,
  getTasksForAccounts,
  getTemplates,
  getVersions,
  publishTaskToLibrary,
  triggerWorkflow,
  updateNumbers,
  updateTask,
  getPhoneNumberSummary,
  getEditHistories,
  requestTaskValidation,
  getTaskValidation,
  duplicateTaskCollection
} from "@/api/tasks";
import _ from "lodash";

import {
  cancelPendingRequests,
  generateCancelToken,
  isErrorDueToCancelledToken
} from "@/utils/studio7ApiService";

const state = {
  tasks: [],
  templates: [],
  phoneNumberSummary: {
    total: 0,
    in_use: 0,
    available: 0
  },
  taskFilter: null,
  taskIdFilter: "-1",
  excludeTaskIdFilter: "0",
  languages: {},
  phoneNumbers: [],
  loading: false,
  taskDuplicating: false,
  taskDuplicationDone: true,
  cancelTokens: []
};

const getters = {
  getTask: state => task_id => {
    return _.find(state.tasks, { task_id: +task_id });
  },

  tasksOfType: state => task_type => {
    return _.filter(
      state.tasks,
      task =>
        task_type && task.task_type.toLowerCase() === task_type.toLowerCase()
    );
  },

  groupedTasksByType: state => {
    return _.groupBy(state.tasks, task => task.task_type);
  },

  getTasks: state => {
    if (state.tasks) {
      return state.tasks;
    } else {
      return [];
    }
  }
};

const mutations = {
  SET_TASKS(state, tasks) {
    state.tasks = tasks;
  },

  SET_NUMBER_SUMMARY(state, phoneNumberSummary) {
    state.phoneNumberSummary = phoneNumberSummary;
  },

  SET_TEMPLATES(state, templates) {
    // state.templates = templates;
    state.templates = templates;
  },

  ADD_TASK(state, task) {
    let existingTaskIndex = state.tasks.findIndex(
      existingTask => existingTask.task_id === task.task_id
    );
    if (existingTaskIndex !== -1) {
      state.tasks.splice(existingTaskIndex, 1, task);
    } else {
      state.tasks.push(task);
    }
  },

  SET_TASK_FILTER_KEY(state, filter) {
    state.taskFilter = filter;
  },

  SET_TASK_NAME_EDITABLE(state, task_id) {
    let task = state.tasks.find(task => task.task_id === task_id);
    task.edit_name = true;
  },
  SET_TASK_NAME_UNEDITABLE(state, task_id) {
    let task = state.tasks.find(task => task.task_id === task_id);
    task.edit_name = false;
  },

  UPDATE_TASK_NAME(state, payload) {
    let task = state.tasks.find(task => task.task_id === payload.task_id);
    task.task_name = payload.task_name;
    task.edit_name = false;
  },

  UPDATE_TASK(state, { index, task }) {
    state.tasks.splice(index, 1, task);
  },

  SET_LANGUAGES(state, languages) {
    state.languages = _.isEmpty(languages) ? {} : languages;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  SET_PHONE_NUMBERS(state, numbers) {
    state.phoneNumbers = numbers;
  },

  SET_TASK_ID_FILTER(state, filter) {
    state.taskIdFilter = filter;
  },

  SET_EXCLUDE_TASK_ID_FILTER(state, filter) {
    state.excludeTaskIdFilter = filter;
  },

  SET_TASK_MODIFIED_FLAG(state, { task_id, modified }) {
    const task = state.tasks.find(task => task.task_id === +task_id);
    if (task) {
      task.version_in_use_modified = modified;
    }
  },

  ADD_TO_CANCEL_TOKENS(state, token) {
    state.cancelTokens.push(token);
  },

  SET_CANCEL_TOKENS(state, tokens) {
    state.cancelTokens = tokens;
  },

  CHANGE_TASK_DUPLICATION_FLAG(state, value) {
    state.taskDuplicating = value;
  },

  CHANGE_TASK_DUPLICATION_DONE_FLAG(state, value) {
    state.taskDuplicationDone = value;
  }
};

const actions = {
  getTasks({ commit, state }, options) {
    //Check if there are any previous pending requests
    cancelPendingRequests(state.cancelTokens);

    // get a new cancel token
    let cancelToken = generateCancelToken();
    commit("ADD_TO_CANCEL_TOKENS", cancelToken);

    return new Promise((resolve, reject) => {
      if (options && !options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
        commit("SET_TASKS", []);
      }
      options = { ...options, task_type: state.taskFilter || "all" };
      getTasks(options, cancelToken.token)
        .then(({ data }) => {
          commit("SET_TASKS", data.data);
          commit("CHANGE_LOADING", false);
          if (state.taskDuplicationDone) {
            commit("CHANGE_TASK_DUPLICATION_FLAG", false);
          }
          cancelPendingRequests(state.cancelTokens);
          commit("SET_CANCEL_TOKENS", []);
          resolve(data);
        })
        .catch(err => {
          if (!isErrorDueToCancelledToken(err)) {
            commit("CHANGE_LOADING", false);
          }
          reject(err);
        });
    });
  },

  getPhoneNumberSummary({ commit }) {
    return new Promise((resolve, reject) => {
      getPhoneNumberSummary({ task_type: state.taskFilter })
        .then(({ data }) => {
          commit("SET_NUMBER_SUMMARY", data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  duplicateTask({ commit }, task) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_TASK_DUPLICATION_DONE_FLAG", false);
      commit("CHANGE_TASK_DUPLICATION_FLAG", true);
      duplicateTask(task.task_id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          // as this leads to an async operation for polling for request status or using websockets
          // the loading is removed when completed or error status is received as the event
        });
    });
  },

  duplicateTaskCollection({ commit }, { task, groupName }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_TASK_DUPLICATION_DONE_FLAG", false);
      commit("CHANGE_TASK_DUPLICATION_FLAG", true);
      duplicateTaskCollection(task.task_id, groupName)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  changeTaskDuplicationDoneFlag({ commit }, val) {
    commit("CHANGE_TASK_DUPLICATION_DONE_FLAG", val);
  },

  changeTaskDuplicatingFlag({ commit }, val) {
    commit("CHANGE_TASK_DUPLICATION_FLAG", val);
  },

  changeLoading({ commit }, val) {
    commit("CHANGE_LOADING", val);
  },

  getTasksForAccounts({ commit }, { spId, accIds, taskType }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getTasksForAccounts(spId, accIds, taskType)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  getLinkTaskList({ commit }, taskId) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getLinkTaskList(taskId)
        .then(({ data }) => {
          resolve(data);
          commit("SET_TASKS", data.data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  getTask({ commit, getters }, task_id) {
    return new Promise((resolve, reject) => {
      let task = getters.getTask(task_id);
      if (!task) {
        commit("CHANGE_LOADING", true);
        getTask(task_id)
          .then(({ data }) => {
            commit("ADD_TASK", data.data);
            resolve(data.data);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      } else {
        resolve(task);
      }
    });
  },

  forceFetchTask({ commit }, task_id) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getTask(task_id)
        .then(({ data }) => {
          // commit("ADD_TASK", data.data);
          resolve(data.data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  taskModified({ commit }, { task_id, modified }) {
    commit("SET_TASK_MODIFIED_FLAG", { task_id, modified });
  },

  getVersions(context, task_id) {
    return new Promise((resolve, reject) => {
      getVersions(task_id)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  createTask({ commit }, { task, async }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createTask(task, async)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateTask({ commit }, { task }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateTask(task)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deleteTask({ commit }, task) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteTask(task)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  setTaskNameEditable({ commit }, task_id) {
    commit("SET_TASK_NAME_EDITABLE", task_id);
  },

  setTaskFilter({ commit }, filter) {
    commit("SET_TASK_FILTER_KEY", filter);
  },

  setTaskNameUnEditable({ commit }, task_id) {
    commit("SET_TASK_NAME_UNEDITABLE", task_id);
  },

  setTaskIdFilter({ commit }, filter) {
    commit("SET_TASK_ID_FILTER", filter);
  },

  setExcludeTaskIdFilter({ commit }, filter) {
    commit("SET_EXCLUDE_TASK_ID_FILTER", filter);
  },

  getLanguages({ commit }) {
    return new Promise((resolve, reject) => {
      getLanguages()
        .then(({ data }) => {
          commit("SET_LANGUAGES", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  getTemplates({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = { ...options, task_type: state.taskFilter, only_published: 1 };
      getTemplates(options)
        .then(({ data }) => {
          commit("SET_TEMPLATES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getPhoneNumbers({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = {
        ...options,
        task_id: state.taskIdFilter,
        exclude_task_id: state.excludeTaskIdFilter,
        task_type: state.taskFilter
      };
      getPhoneNumbers(options)
        .then(({ data }) => {
          commit("SET_PHONE_NUMBERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  updateNumbers({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateNumbers(data)
        .then(res => {
          commit("CHANGE_LOADING", false);
          resolve(res);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  updateTaskName({ commit }, task) {
    return new Promise((resolve, reject) => {
      updateTask(task)
        .then(res => {
          commit("UPDATE_TASK_NAME", task);
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateTaskFavorite({ commit, state }, task) {
    return new Promise((resolve, reject) => {
      let taskToUpdate = _.cloneDeep(task);
      taskToUpdate.is_favorite = !task.is_favorite;
      let index = _.findIndex(state.tasks, oldTask => {
        return oldTask.task_id === task.task_id;
      });
      commit("UPDATE_TASK", { index, task: taskToUpdate });
      updateTask(taskToUpdate)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateTaskLock({ commit, state }, task) {
    return new Promise((resolve, reject) => {
      let taskToUpdate = _.cloneDeep(task);
      taskToUpdate.protected = !task.protected;
      let index = _.findIndex(state.tasks, oldTask => {
        return oldTask.task_id === task.task_id;
      });
      commit("UPDATE_TASK", { index, task: taskToUpdate });
      updateTask(taskToUpdate)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  undoDeleteTask({ commit }, task) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      task.is_enabled = 1;
      console.log("Undo Delete ", task);
      updateTask(task)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  triggerWorkflow({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      triggerWorkflow(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },
  publishTask({ commit }, { template, task_id, async }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      publishTaskToLibrary(template, task_id, async)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getEditHistories(context, task_id) {
    return new Promise((resolve, reject) => {
      getEditHistories(task_id)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  requestTaskValidation(context, task_id) {
    return new Promise((resolve, reject) => {
      requestTaskValidation(task_id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getTaskValidation(context, task_id) {
    return new Promise((resolve, reject) => {
      getTaskValidation(task_id)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
